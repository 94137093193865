import { styled } from '@mui/material/styles'
import { Box, Dialog, TableContainer, Paper } from '@mui/material'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '800px',
    maxWidth: '1000px',
    borderRadius: '12px'
  }
}))

export const ChartContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}))

export const StatBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '200px',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
  },
  '& .MuiTypography-subtitle1': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  '& .MuiTypography-h6': {
    color: theme.palette.primary.main,
    fontWeight: 600,
  }
}))

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .MuiTableCell-head': {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.default
  }
}))

export const SummaryContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexWrap: 'wrap',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3)
}))