import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../util/axios'
import { playground, usageApi } from '../../services/Api'

export const openaiChat = createAsyncThunk('openaiChat', async (payload) => {
  try {
    const res = await axiosInstance.post(playground.openaiChat(payload.userId), payload.userData)
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error?.response
  }
})

export const geminiChat = createAsyncThunk('geminiChat', async (payload) => {
  try {
    const res = await axiosInstance.post(playground.geminiChat(payload.userId), payload.userData)
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error?.response
  }
})

export const claudeChat = createAsyncThunk('claudeChat', async (payload) => {
  try {
    const res = await axiosInstance.post(playground.claudeChat(payload.userId), payload.userData)
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error?.response
  }
})

export const chatWithModel = createAsyncThunk('chatWithModel', async (payload) => {
  try {
    const res = await axiosInstance.post(playground.chatWithModel(payload.userId), payload.userData)
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error?.response
  }
})

export const publishPrompt = createAsyncThunk('publishPrompt', async (payload) => {
  try {
    const res = await axiosInstance.post(playground.publishPrompt(payload.userId), payload.publishObject)
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const getPromptDetails = createAsyncThunk('getPromptDetails', async (payload) => {
  try {
    const res = await axiosInstance.get(
      playground.getPromptDetails(
        payload?.userId,
        payload?.projectId,
        payload?.folderId,
        payload?.promptId,
      ),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const getPromptVersions = createAsyncThunk('getPromptVersions', async (payload) => {
  try {
    const res = await axiosInstance.get(
      playground.getPromptVersions(
        payload?.userId,
        payload?.projectId,
        payload?.folderId,
        payload?.promptId,
      ),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const updateIsPublished = createAsyncThunk('updateIsPublished', async (payload) => {
  try {
    const res = await axiosInstance.patch(
      playground.updateIsPublished(
        payload?.userId,
        payload?.projectId,
        payload?.folderId,
        payload?.promptId,
        payload?.versionId,
      ),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const getVersionDetails = createAsyncThunk('getVersionDetails', async (payload) => {
  try {
    const res = await axiosInstance.get(
      playground.getVersionDetails(
        payload?.userId,
        payload?.projectId,
        payload?.folderId,
        payload?.promptId,
        payload?.versionId,
      ),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      payload.meta?.onError?.(res?.error)
      throw res?.error
    }
  } catch (error) {
    payload.meta?.onError?.(error?.response)
    return error.message
  }
})

export const getUsageByVersion = createAsyncThunk('getUsageByVersion', async (payload) => {
  console.log('Payload:', payload)
  try {
    const res = await axiosInstance.get(
      usageApi.getUsageByVersion(
        payload?.versionId,
        payload?.page,
        payload?.limit,
        payload?.startDate,
        payload?.endDate,
        payload?.tags,
      ),
    )
    if (res.data) {
      payload.meta?.onSuccess?.(res.data)
      return res.data
    } else {
      const error = new Error(res?.error || 'Failed to fetch usage data')
      payload.meta?.onError?.(error)
      throw error
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.message || error?.message || 'Failed to fetch usage data'
    payload.meta?.onError?.(errorMessage)
    throw new Error(errorMessage)
  }
})
