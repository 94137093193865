import React, { useState } from 'react';
import { Box, Button, TextField, IconButton, Typography, Divider } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';

const UsageFilter = ({ startDate, endDate, onStartDateChange, onEndDateChange, onReset, onSearch }) => {
  const [tags, setTags] = useState([{ key: '', value: '' }]);

  const handleAddTag = () => {
    setTags([...tags, { key: '', value: '' }]);
  };

  const handleRemoveTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
  };

  const handleTagChange = (index, field, value) => {
    const newTags = tags.map((tag, i) => {
      if (i === index) {
        return { ...tag, [field]: value };
      }
      return tag;
    });
    setTags(newTags);
  };

  const handleSearch = () => {
    const validTags = tags.filter(tag => tag.key && tag.value);
    const tagParams = {};
    validTags.forEach(tag => {
      tagParams[tag.key] = tag.value;
    });
    onSearch(tagParams);
  };

  const handleReset = () => {
    setTags([{ key: '', value: '' }]);
    onReset();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
        {/* Reset button at the top right */}
        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            variant="outlined" 
            onClick={handleReset}
            size="small"
          >
            Reset All Filters
          </Button>
        </Box> */}

        {/* Date Filter Section */}
        <Box>
          {/* <Typography variant="subtitle2" sx={{ mb: 1 }}>Date Range</Typography> */}
          <Box sx={{ display: 'flex', gap: 2, marginTop: 1 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={onStartDateChange}
              format="DD/MM/YYYY"
              slotProps={{ textField: { size: 'small' } }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={onEndDateChange}
              minDate={startDate}
              format="DD/MM/YYYY"
              slotProps={{ textField: { size: 'small' } }}
            />
          </Box>
        </Box>

        <Divider />

        {/* Tags Filter Section */}
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Tags</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {tags.map((tag, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <TextField
                  size="small"
                  label="Key"
                  value={tag.key}
                  onChange={(e) => handleTagChange(index, 'key', e.target.value)}
                  sx={{ flexGrow: 1 }}
                />
                <TextField
                  size="small"
                  label="Value"
                  value={tag.value}
                  onChange={(e) => handleTagChange(index, 'value', e.target.value)}
                  sx={{ flexGrow: 1 }}
                />
                <IconButton 
                  onClick={() => handleRemoveTag(index)}
                  disabled={tags.length === 1}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              onClick={handleAddTag}
              sx={{ alignSelf: 'flex-start' }}
              size="small"
            >
              Add Tag
            </Button>
          </Box>
        </Box>

        {/* Search Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
            variant="outlined" 
            onClick={handleReset}
            size="small"
            sx={{ mr: 2 }}
          >
            Reset All Filters
          </Button>
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default UsageFilter;
