import React, { useEffect, useState } from 'react'
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material'
import BreadCrumb from '../breadCrumb'

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'
import {
  setIsPublished,
  setJsonMode,
  setMessagesCompare,
  setMessagesCompareInitial,
  setMessagesInitial,
  setParameters,
  setResponseFormat,
  setResponseFormatCompare,
  setSelectedAi,
  setSelectedModel,
  setSelectedModelCompare,
  setSystemValue,
  setSystemValueCompare,
  setVariableCompareKey,
  setVariableKey,
  setVariables,
  setVariablesCompare,
  setJsonModeCompare,
  setParametersCompare,
  setSelectedAiCompare,
  setIsImageSupport,
  setIsAudioSupport,
} from '../../redux/slice/Conversation'
import { useDispatch, useSelector } from 'react-redux'
import { CopyButton, IconBox, ToggleButton } from './studioBodyStyles'
import StudioView from '../studioView'
import { AI_MODELS, MODEL_OPTIONS, USEISCOMPARETOGGLE } from '../../constants/Constants'
import { Button } from 'react-scroll'
import {
  setPromptVersions,
  setPublishedPromptVersions,
  setSelectedPromptVersion,
} from '../../redux/slice/UserProjects'
import { savePrompt } from '../../redux/action/UserProjectsAction'
import { useAuth } from '../../AuthContext'
import { toast } from 'react-toastify'
import { getPromptVersions, getVersionDetails } from '../../redux/action/PlaygroundAction'
import { CommentsDisabledOutlined } from '@mui/icons-material'
import { checkImageSupport, generateNewVersion } from '../../util/helpers'

const StudioBody = ({
  handleRightSidebar,
  handleRightSidebarCompare,
  selectedModelOptions,
  setSelectedModelOptions,
  selectedCategory,
  setSelectedCategory,
  selectedModelOptionsCompare,
  setSelectedModelOptionsCompare,
  selectedCategoryCompare,
  setSelectedCategoryCompare,
}) => {
  const [show, setShow] = useState(false)
  const [toggleFirst, setToggleFirst] = useState(true)
  const [toggleSecond, setToggleSecond] = useState(false)
  const [copy, setCopy] = useState(false)
  const [written, setWritten] = useState(false)
  const { parameters, selectedAi, selectedAiCompare } = useSelector((state) => state.conversations)
  const { selectedProjectId, selectedFolderId, selectedPrompt } = useSelector(
    (state) => state.UserProjects,
  )

  const isCompareToggle = USEISCOMPARETOGGLE()

  const dispatch = useDispatch()
  const {
    systemValue,
    messages,
    messagesCompare,
    systemValueCompare,
    selectedModel,
    isPublished,
    selectedModelCompare,
    variablesCompare,
    variables,
    variableKey,
    variableKeyCompare,
    responseFormat,
    responseFormatCompare,
    jsonMode,
    jsonModeCompare,
    parametersCompare,
  } = useSelector((state) => state.conversations)
  const { promptVersions, selectedPromptVersion } = useSelector((state) => state.UserProjects)
  const { userInfo } = useAuth()

  const handleSaveOnCheckout = (savePromptData, version) => {
    try {
      dispatch(
        savePrompt({
          userId: userInfo.userId,
          projectId: selectedProjectId,
          folderId: selectedFolderId,
          promptId: selectedPrompt?.promptId,
          version: version,
          savePromptData,
          meta: {
            onSuccess: (res) => {
              dispatch(
                setPromptVersions({ ...promptVersions, [res.data.result.version_id]: version }),
              )
              dispatch(setSelectedPromptVersion(res.data.result.version_id))
            },
            onError: (error) => {
              toast.error('something went wrong while saving the prompt')
            },
          },
        }),
      )
    } catch (error) {
      console.log('error for save', error)
    }
  }

  const checkWritten = () => {
    if (messagesCompare.length === 0 && systemValueCompare === '') {
      return 'empty'
    } else if (
      JSON.stringify(messages) === JSON.stringify(messagesCompare) &&
      systemValue === systemValueCompare
    ) {
      return 'same'
    } else {
      return 'altered'
    }
  }

  const handleCopyNewVersion = (newVersion) => {
    // populate data ---
    dispatch(setSystemValue(systemValueCompare))
    dispatch(setMessagesInitial(messagesCompare))

    setSelectedModelOptions(MODEL_OPTIONS[selectedModelCompare])
    setSelectedCategory(MODEL_OPTIONS[selectedModelCompare][0])
    dispatch(setSelectedAi(selectedModelCompare))
    dispatch(setIsPublished(false))

    const NewMessages = []
    messagesCompare?.map((item) => {
      NewMessages.push({
        role: item.role,
        content: item.content.map((contentItem) => {
          if (contentItem.type === 'text') {
            return {
              type: 'text',
              text:
                typeof contentItem.text === 'string'
                  ? contentItem.text
                  : JSON.stringify(contentItem.text),
            }
          } else if (contentItem.type === 'file') {
            return {
              type: 'file',
              file_url: contentItem.file_url,
            }
          }
          // Handle any other content types if necessary
          return contentItem
        }),
      })
    })

    const modifiedVariables = []

    variablesCompare.map((item) => modifiedVariables.push({ name: item.key, value: item.value }))

    const savePromptData = {
      messages: NewMessages,
      isPublished: false,
      platform: selectedModelCompare === 'claudeai' ? 'claude' : selectedModelCompare,
      model: selectedCategoryCompare,
      variable: modifiedVariables,
      system_message: systemValueCompare,
      temp: parameters[selectedModelCompare]?.temperature || 1,
      max_tokens: parameters[selectedModelCompare]?.maxTokens || 1,
      top_p: parameters[selectedModelCompare]?.topP || null,
      frequency_penalty: parameters[selectedModelCompare]?.frequencyPenalty || 1,
      presence_penalty: parameters[selectedModelCompare]?.presencePenalty || 1,
      top_k: parameters[selectedModelCompare]?.topK || 1,
      ...(selectedModel === 'openai' &&
        jsonMode &&
        selectedCategory !== 'gpt-3.5-turbo' && {
          response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormatCompare) },
        }),
    }

    handleSaveOnCheckout(savePromptData, newVersion)
  }

  const copyfromRightToLeft = () => {
    handleToggle1()
    dispatch(setSystemValue(systemValueCompare))
    dispatch(setMessagesInitial(messagesCompare))
    dispatch(setVariables(variablesCompare))
    dispatch(setVariableKey(variableKeyCompare))
    dispatch(setSelectedModel(selectedModelCompare))
    setSelectedCategory(selectedCategoryCompare)
    dispatch(setResponseFormat(responseFormatCompare))
    dispatch(setJsonMode(jsonModeCompare))
    dispatch(setParameters(parametersCompare))
  }

  const copyFromLeftToRight = () => {
    setCopy(true)
    dispatch(setSystemValueCompare(systemValue))
    dispatch(setMessagesCompareInitial(messages))
    dispatch(setVariablesCompare(variables))
    dispatch(setVariableCompareKey(variableKey))
    dispatch(setSelectedModelCompare(selectedModel))
    dispatch(setResponseFormatCompare(responseFormat))
    dispatch(setJsonModeCompare(jsonMode))
    dispatch(setParametersCompare(parameters))
    setSelectedCategoryCompare(selectedCategory)
    handleToggle2()
  }

  const HandleCopyRightClick = () => {
    const writtenStatus = checkWritten()

    if (writtenStatus === 'altered') {
      Swal.fire({
        title: 'Data will be over written',
        text: 'This action will overrite the right window with the content from left window ',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Copy',
        showCancelButton: true,
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.isConfirmed) {
          // handleClear()
          copyFromLeftToRight()
        }
      })
    } else {
      copyFromLeftToRight()
    }
  }

  const HandleCopyLeftClick = () => {
    const writtenStatus = checkWritten()
    const newVersion = generateNewVersion(promptVersions)
    if (isPublished) {
      Swal.fire({
        title: 'Copy to left',
        text: `This will create a new version ${newVersion}`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Copy',
        showCancelButton: true,
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.isConfirmed) {
          handleCopyNewVersion(newVersion)
        }
      })
    } else if (writtenStatus === 'altered') {
      Swal.fire({
        title: 'Data will be over written',
        text: 'This action will overrite the left window with the content from right window ',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Copy',
        showCancelButton: true,
        cancelButtonText: 'Close',
      }).then((result) => {
        if (result.isConfirmed) {
          // handleClear()
          copyfromRightToLeft()
        }
      })
    }
  }

  const handleToggle1 = () => {
    setToggleFirst(true)
    setToggleSecond(false)
  }

  const handleToggle2 = () => {
    setToggleFirst(false)
    setToggleSecond(true)
  }

  const fetchVersionData = async () => {
    const userId = userInfo?.userId
    const projectId = selectedProjectId
    const folderId = selectedFolderId
    const promptId = selectedPrompt?.promptId
    const versionId = promptVersions[selectedPromptVersion]

    try {
      dispatch(
        getVersionDetails({
          userId,
          projectId,
          folderId,
          promptId,
          versionId,
          meta: {
            onSuccess: (data) => {
              const DEFAULT_PARAMETERS = {
                openai: {
                  temperature: data?.data?.result?.prompt?.aiPlatform?.temp || 1, // Default value if not provided
                  maxTokens: data?.data?.result?.prompt?.aiPlatform?.max_tokens || 100, // Default value if not provided
                  topP: data?.data?.result?.prompt?.aiPlatform?.top_p || 0.5, // Default value if not provided
                  frequencyPenalty:
                    data?.data?.result?.prompt?.aiPlatform?.frequency_penalty || 0.5, // Default value if not provided
                  presencePenalty: data?.data?.result?.prompt?.aiPlatform?.presence_penalty || 0.5, // Default value if not provided
                },
                claudeai: {
                  temperature: data?.data?.result?.prompt?.aiPlatform?.temp || 1, // Default value if not provided
                  maxTokens: data?.data?.result?.prompt?.aiPlatform?.max_tokens || 100, // Default value if not provided
                },
                gemini: {
                  temperature: data?.data?.result?.prompt?.aiPlatform?.temp || 1, // Default value if not provided
                  maxTokens: data?.data?.result?.prompt?.aiPlatform?.max_tokens || 100, // Default value if not provided
                  topP: data?.data?.result?.prompt?.aiPlatform?.top_p || 0.5,
                },
                groq: {
                  temperature: data?.data?.result?.prompt?.aiPlatform?.temp || 1, // Default value if not provided
                  maxTokens: data?.data?.result?.prompt?.aiPlatform?.max_tokens || 100, // Default value if not provided
                  topP: data?.data?.result?.prompt?.aiPlatform?.top_p || 0.5,
                },
                grok: {
                  temperature: data?.data?.result?.prompt?.aiPlatform?.temp || 1, // Default value if not provided
                  maxTokens: data?.data?.result?.prompt?.aiPlatform?.max_tokens || 100, // Default value if not provided
                  topP: data?.data?.result?.prompt?.aiPlatform?.top_p || 0.5,
                  frequencyPenalty:
                    data?.data?.result?.prompt?.aiPlatform?.frequency_penalty || 0.5, // Default value if not provided
                  presencePenalty: data?.data?.result?.prompt?.aiPlatform?.presence_penalty || 0.5, // Default value if not provided
                },
                deepseek: {
                  temperature: data?.data?.result?.prompt?.aiPlatform?.temp || 1, // Default value if not provided
                  maxTokens: data?.data?.result?.prompt?.aiPlatform?.max_tokens || 100, // Default value if not provided
                },
                custom: {
                  temperature: data?.data?.result?.prompt?.aiPlatform?.temp || 1, // Default value if not provided
                  maxTokens: data?.data?.result?.prompt?.aiPlatform?.max_tokens || 100, // Default value if not provided
                  topP: data?.data?.result?.prompt?.aiPlatform?.top_p || 0.5,
                  frequencyPenalty:
                    data?.data?.result?.prompt?.aiPlatform?.frequency_penalty || 0.5, // Default value if not provided
                  presencePenalty: data?.data?.result?.prompt?.aiPlatform?.presence_penalty || 0.5, // Default value if not provided
                },
              }

              dispatch(
                setSelectedModel(
                  data?.data?.result?.prompt?.aiPlatform?.platform === 'claude'
                    ? 'claudeai'
                    : data?.data?.result?.prompt?.aiPlatform?.platform,
                ),
              )
              if (data?.data?.result?.prompt?.aiPlatform?.response_format) {
                const platform = data?.data?.result?.prompt?.aiPlatform?.platform
                const responseFormat = data?.data?.result?.prompt?.aiPlatform?.response_format

                if (platform === 'openai') {
                  dispatch(setResponseFormat(JSON.stringify(responseFormat?.json_schema)))
                  dispatch(setJsonMode(responseFormat?.type === 'json_schema'))
                } else if (platform === 'gemini') {
                  dispatch(setResponseFormat(JSON.stringify(responseFormat)))
                  dispatch(
                    setJsonMode(
                      responseFormat?.type === 'object' || responseFormat?.type === 'array',
                    ),
                  )
                } else {
                  dispatch(setResponseFormat(''))
                  dispatch(setJsonMode(false))
                }
              } else {
                dispatch(setResponseFormat(''))
              }
              dispatch(setIsImageSupport(data?.data?.result?.prompt?.isImageSupport))
              dispatch(setIsAudioSupport(data?.data?.result?.prompt?.isAudioSupport))
              dispatch(setMessagesInitial(data?.data?.result?.messages?.messages))
              dispatch(setSystemValue(data?.data?.result?.messages?.systemMessage))
              setTimeout(() => {
                setSelectedCategory(data?.data?.result?.prompt?.aiPlatform?.model)
              }, [0])

              const newVariable = []
              data?.data?.result?.messages?.variable?.map((item) => {
                newVariable.push({ key: item.name, value: item.value })
              })

              const variableKey =
                data?.data?.result?.messages?.variable?.map((item) => item.name) || []

              dispatch(setVariables(newVariable))
              dispatch(setVariableKey(variableKey))
              dispatch(setIsPublished(data?.data?.result?.prompt?.isPublished))

              dispatch(setParameters(DEFAULT_PARAMETERS))
            },
            onError: (error) => {
              // toast.error(error?.data?.message)
              console.error('Fetching failed:', error)
              dispatch(setIsPublished(false))
              // setAddLoading(false)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during Updating:', error)
    }
  }

  const fetchPromptVersions = () => {
    try {
      dispatch(
        getPromptVersions({
          userId: userInfo?.userId,
          projectId: selectedProjectId,
          folderId: selectedFolderId,
          promptId: selectedPrompt?.promptId,
          meta: {
            onSuccess: (res) => {
              if (res?.data?.status_code === 200) {
                const versionList = res?.data?.versions
                dispatch(setPromptVersions(versionList))
                dispatch(setPublishedPromptVersions(res?.data?.published_versions))
                if (!Object.keys(versionList).find((v) => v === selectedPromptVersion)) {
                  if (versionList && Object.keys(versionList).length > 0) {
                    const latestVersionKey = Object.keys(versionList).reduce((a, b) =>
                      versionList[a] > versionList[b] ? a : b,
                    )
                    dispatch(setSelectedPromptVersion(latestVersionKey))
                  }
                }
              }
            },
            onError: (error) => {
              if (error.data.status_code === 417) {
                // const tempId = uuidv4()
                // dispatch(setPromptVersions({ [tempId]: 1.0 }))
                // dispatch(setSelectedPromptVersion(tempId))
                const savePromptData = {
                  messages: [],
                  isPublished: false,
                  platform: selectedModel,
                  model: MODEL_OPTIONS[AI_MODELS[0]][0],
                  variable: [],
                  system_message: '',
                  temp: parameters[selectedModel]?.temperature || 1,
                  max_tokens: parameters[selectedModel]?.maxTokens || 1,
                  top_p: parameters[selectedModel]?.topP || null,
                  frequency_penalty: parameters[selectedModel]?.frequencyPenalty || 1,
                  presence_penalty: parameters[selectedModel]?.presencePenalty || 1,
                  top_k: parameters[selectedModel]?.topK || 1,
                }
                handleSaveOnCheckout(savePromptData, 1.0)
              } else {
                dispatch(setSelectedPromptVersion(''))
                // dispatch(setPromptVersions([]))
                // toast.error(error.data.message)
              }
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during fetch:', error)
    }
  }

  useEffect(() => {
    if (userInfo?.userId && promptVersions?.[selectedPromptVersion]) {
      fetchVersionData()
    }
  }, [selectedPromptVersion, selectedPrompt])

  useEffect(() => {
    if (userInfo?.userId && selectedProjectId !== '' && selectedPrompt !== '') {
      fetchPromptVersions()
    }
  }, [selectedPrompt])

  useEffect(() => {
    const writtenStatus = checkWritten()
    setWritten(writtenStatus === 'altered')
  }, [messages, messagesCompare, systemValue, systemValueCompare])

  useEffect(() => {
    if (MODEL_OPTIONS && MODEL_OPTIONS[selectedModel]) {
      setSelectedModelOptions(MODEL_OPTIONS[selectedModel])
      setSelectedCategory(MODEL_OPTIONS[selectedModel][0])
      dispatch(setSelectedAi(selectedModel))
      // console.log('First Use effect')
    }
  }, [selectedModel, selectedAi])

  useEffect(() => {
    if (MODEL_OPTIONS && MODEL_OPTIONS[selectedModelCompare]) {
      setSelectedModelOptionsCompare(MODEL_OPTIONS[selectedModelCompare])
      setSelectedCategoryCompare(MODEL_OPTIONS[selectedModelCompare][0])
      dispatch(setSelectedAiCompare(selectedModelCompare))
      // updateSelectedModel(selectedModelCompare)
    }
  }, [selectedModelCompare, setSelectedAiCompare])

  return (
    <Stack flexDirection={'column'} width={'100%'} p={2} gap={2} height={'100%'}>
      <Stack>
        <BreadCrumb />
      </Stack>

      <Stack flexDirection={'row'} width={'100%'} gap={2} height={'100%'}>
        {/* Render the first StudioView if toggleFirst is true or show is false when isCompareToggle is true */}
        {(!isCompareToggle || toggleFirst || !show) && (
          <StudioView
            handleRightSidebar={handleRightSidebar}
            showSecond={setShow}
            show={show}
            component={1}
            selectedModelOptions={selectedModelOptions}
            setSelectedModelOptions={setSelectedModelOptions}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedModelOptionsCompare={selectedModelOptionsCompare}
            setSelectedModelOptionsCompare={setSelectedModelOptionsCompare}
            selectedCategoryCompare={selectedCategoryCompare}
            setSelectedCategoryCompare={setSelectedCategoryCompare}
            HandleCopyRightClick={HandleCopyRightClick}
            HandleCopyLeftClick={HandleCopyLeftClick}
            handleToggle1={handleToggle1}
            handleToggle2={handleToggle2}
            toggleFirst={toggleFirst}
            toggleSecond={toggleSecond}
            handleSaveOnCheckout={handleSaveOnCheckout}
          />
        )}
        {/* Render the second StudioView if both show and toggleSecond are true */}
        {show && (!isCompareToggle || toggleSecond) && (
          <>
            {!isCompareToggle && (
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Divider orientation="vertical">
                  <Tooltip title={'Copy to Left'} placement="top">
                    <IconBox>
                      <KeyboardDoubleArrowLeftIcon onClick={HandleCopyLeftClick} />
                    </IconBox>
                  </Tooltip>

                  <Tooltip title={'Copy to Right'}>
                    <IconBox>
                      <KeyboardDoubleArrowRightIcon onClick={HandleCopyRightClick} />
                    </IconBox>
                  </Tooltip>
                </Divider>
              </Box>
            )}
            <StudioView
              handleRightSidebarCompare={handleRightSidebarCompare}
              showSecond={setShow}
              show={show}
              copy={copy}
              HandleClose={() => setCopy(false)}
              HandleWritten={setWritten}
              component={2}
              selectedModelOptions={selectedModelOptions}
              setSelectedModelOptions={setSelectedModelOptions}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedModelOptionsCompare={selectedModelOptionsCompare}
              setSelectedModelOptionsCompare={setSelectedModelOptionsCompare}
              selectedCategoryCompare={selectedCategoryCompare}
              setSelectedCategoryCompare={setSelectedCategoryCompare}
              HandleCopyRightClick={HandleCopyRightClick}
              HandleCopyLeftClick={HandleCopyLeftClick}
              handleToggle1={handleToggle1}
              handleToggle2={handleToggle2}
              toggleFirst={toggleFirst}
              toggleSecond={toggleSecond}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default StudioBody
