import React, { useEffect, useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
  Popover
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ChatIcon from '@mui/icons-material/Chat'
import { StyledDialog, ChartContainer, StatBox, StyledTableContainer, SummaryContainer } from './usageDialogStyles'
import { useDispatch } from 'react-redux'
import { getUsageByVersion } from '../../../redux/action/PlaygroundAction'
import UsageFilter from '../../common/DateRangeFilter'
import dayjs from 'dayjs'
import ChatPopover from './chatPopover'

const Row = ({ row, index, selectedCurrency }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const hasInteractions = row?.interactions?.length > 0;

  const totalInteractionCost = hasInteractions ? 
    row.interactions.reduce((sum, interaction) => 
      selectedCurrency === 'USD' 
        ? sum + interaction.interaction_price.price_in_usd
        : sum + interaction.interaction_price.price_in_inr
    , 0) : 
    selectedCurrency === 'USD' ? row.price.price_in_usd : row.price.price_in_inr;

  const currencySymbol = selectedCurrency === 'USD' ? '$' : '₹';

  const hasMessages = hasInteractions 
    ? row.interactions.some(i => i.messages?.length > 0)
    : row.messages?.length > 0;

  const handleChatClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseChatPopover = () => {
    setAnchorEl(null);
  };

  const messages = hasInteractions
    ? row.interactions.flatMap(i => i.messages || [])
    : row.messages || [];

  return (
    <>
      <TableRow 
        sx={{ 
          '& > *': { borderBottom: hasInteractions && open ? 'none' : 'inherit' },
          backgroundColor: hasInteractions ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
          '&:hover': {
            backgroundColor: hasInteractions ? 'rgba(0, 0, 0, 0.08)' : 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <TableCell padding="checkbox">
          {hasInteractions && (
            <IconButton
              size="small"
              onClick={() => setOpen(!open)}
              sx={{ ml: 1 }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{new Date(row.timestamp).toLocaleString()}</TableCell>
        <TableCell>
          <Chip 
            label={`${row.platform} / ${row.model}`} 
            size="small" 
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          {row.requestFrom && (
            <Chip 
              label={row.requestFrom}
              size="small"
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
            />
          )}
        </TableCell>
        <TableCell align="right">{row.input_tokens}</TableCell>
        <TableCell align="right">{row.output_tokens}</TableCell>
        <TableCell align="right">{currencySymbol}{totalInteractionCost.toFixed(6)}</TableCell>
        <TableCell padding="checkbox">
          {hasMessages && (
            <IconButton
              size="small"
              onClick={handleChatClick}
              sx={{ ml: 1 }}
            >
              <ChatIcon fontSize="small" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {hasInteractions && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ 
                margin: 1, 
                backgroundColor: 'background.paper',
                borderRadius: 1,
                boxShadow: 1,
                p: 2
              }}>
                <Typography variant="subtitle2" gutterBottom component="div" sx={{ fontWeight: 600, mb: 2 }}>
                  Session Details
                </Typography>
                <Table size="small" sx={{ mb: 0 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell align="right">Input Tokens</TableCell>
                      <TableCell align="right">Output Tokens</TableCell>
                      <TableCell align="right">Cost ({selectedCurrency})</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.interactions.map((interaction, i) => (
                      <TableRow key={i}>
                        <TableCell>{new Date(interaction.initiatedAt).toLocaleTimeString()}</TableCell>
                        <TableCell>
                          <Chip 
                            label={interaction.requestFrom}
                            size="small"
                            variant="outlined"
                            sx={{ backgroundColor: 'white' }}
                          />
                        </TableCell>
                        <TableCell align="right">{interaction.input_tokens}</TableCell>
                        <TableCell align="right">{interaction.output_tokens}</TableCell>
                        <TableCell align="right">
                          {currencySymbol}
                          {(selectedCurrency === 'USD' 
                            ? interaction.interaction_price.price_in_usd 
                            : interaction.interaction_price.price_in_inr
                          ).toFixed(6)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseChatPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden'
          }
        }}
      >
        <ChatPopover messages={messages} onClose={handleCloseChatPopover} />
      </Popover>
    </>
  );
};

const UsageDialog = ({ open, onClose, versionId }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [usageData, setUsageData] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [tagFilters, setTagFilters] = useState({})
  const [selectedCurrency, setSelectedCurrency] = useState('USD')

  const fetchUsageData = (pageNum) => {
    setLoading(true)
    setError(null)
    dispatch(getUsageByVersion({
      versionId,
      page: pageNum + 1,
      limit: rowsPerPage,
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
      tags: tagFilters,
      meta: {
        onSuccess: (data) => {
          setUsageData(data)
          setLoading(false)
        },
        onError: (error) => {
          setError(error.toString())
          setLoading(false)
        }
      }
    }))
  }

  useEffect(() => {
    if (open && versionId) {
      fetchUsageData(page)
    }
  }, [open, versionId, page, rowsPerPage, startDate, endDate, tagFilters])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleStartDateChange = (date) => {
    setStartDate(date)
    setPage(0)
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
    setPage(0)
  }

  const handleReset = () => {
    setStartDate(null)
    setEndDate(null)
    setTagFilters({})
    setPage(0)
  }

  const handleSearch = (tags) => {
    setTagFilters(tags)
    setPage(0)
  }

  const handleCurrencyChange = (event, newCurrency) => {
    if (newCurrency !== null) {
      setSelectedCurrency(newCurrency);
    }
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth={false}
      sx={{
        '& .MuiDialog-paper': {
          width: '90vw',
          maxWidth: '1400px'
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h6">Usage Statistics</Typography>
          <ToggleButtonGroup
            value={selectedCurrency}
            exclusive
            onChange={handleCurrencyChange}
            size="small"
          >
            <ToggleButton value="USD">USD</ToggleButton>
            <ToggleButton value="INR">INR</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <UsageFilter
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={handleStartDateChange}
          onEndDateChange={handleEndDateChange}
          onReset={handleReset}
          onSearch={handleSearch}
        />
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        ) : usageData ? (
          <ChartContainer>
            <SummaryContainer>
              <StatBox>
                <Typography variant="subtitle1">Total Input Tokens</Typography>
                <Typography variant="h6">
                  {usageData?.data?.usage_summary?.total_input_tokens?.toLocaleString()}
                </Typography>
              </StatBox>
              <StatBox>
                <Typography variant="subtitle1">Total Output Tokens</Typography>
                <Typography variant="h6">
                  {usageData?.data?.usage_summary?.total_output_tokens?.toLocaleString()}
                </Typography>
              </StatBox>
              {/* <StatBox>
                <Typography variant="subtitle1">Total Sessions</Typography>
                <Typography variant="h6">
                  {usageData?.data?.usage_summary?.session_statistics?.total_sessions}
                </Typography>
              </StatBox> */}
              <StatBox>
                <Typography variant="subtitle1">Total Cost ({selectedCurrency})</Typography>
                <Typography variant="h6">
                  {selectedCurrency === 'USD' ? '$' : '₹'}
                  {(selectedCurrency === 'USD'
                    ? usageData?.data?.usage_summary?.total_cost?.price_in_usd
                    : usageData?.data?.usage_summary?.total_cost?.price_in_inr
                  )?.toFixed(6)}
                </Typography>
              </StatBox>
              <StatBox>
                <Typography variant="subtitle1">Avg. Cost/Interaction ({selectedCurrency})</Typography>
                <Typography variant="h6">
                  {selectedCurrency === 'USD' ? '$' : '₹'}
                  {(selectedCurrency === 'USD'
                    ? usageData?.data?.usage_summary?.average_cost_per_interaction?.price_in_usd
                    : usageData?.data?.usage_summary?.average_cost_per_interaction?.price_in_inr
                  )?.toFixed(6)}
                </Typography>
              </StatBox>
              <StatBox>
                <Typography variant="subtitle1">Avg. Cost/Session ({selectedCurrency})</Typography>
                <Typography variant="h6">
                  {selectedCurrency === 'USD' ? '$' : '₹'}
                  {(selectedCurrency === 'USD'
                    ? usageData?.data?.usage_summary?.session_statistics?.average_cost_per_session?.price_in_usd
                    : usageData?.data?.usage_summary?.session_statistics?.average_cost_per_session?.price_in_inr
                  )?.toFixed(6)}
                </Typography>
              </StatBox>
            </SummaryContainer>

            <StyledTableContainer component={Box}>
              {usageData?.data?.results?.length > 0 ? (
                <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="48px" /> {/* For expand/collapse icon */}
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell align="right">Input Tokens</TableCell>
                    <TableCell align="right">Output Tokens</TableCell>
                    <TableCell align="right">Cost ({selectedCurrency})</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usageData?.data?.results?.map((row, index) => (
                    <Row key={index} row={row} index={index} selectedCurrency={selectedCurrency} />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={usageData.data.pagination.total_items}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
                </>
              ) : (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  height: '200px',
                  backgroundColor: 'background.paper',
                  borderRadius: 1,
                  mt: 2
                }}>
                  <Typography variant="body1" color="text.secondary">
                    No usage entries found
                  </Typography>
                </Box>
              )}
            </StyledTableContainer>
          </ChartContainer>
        ) : null}
      </DialogContent>
    </StyledDialog>
  )
}

export default UsageDialog