import { Box, Grid, IconButton, Stack, Tooltip, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AIModelDropdown from '../dropdown'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import { v4 as uuidv4 } from 'uuid'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BackupIcon from '@mui/icons-material/Backup'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import CodeIcon from '@mui/icons-material/Code'
import BarChartIcon from '@mui/icons-material/BarChart'
import {
  AI_MODELS,
  USEISCOMPARETOGGLE,
  VERSIONS,
  USEISMENU,
  USEISMOBILE,
  MODEL_OPTIONS,
  MODEL_FEATURES,
} from '../../constants/Constants'
import {
  CompareBox,
  CompareIconBox,
  CompareToggleStack,
  MainBox,
  OptionBox,
  PublishButton,
} from './studioToolbarStyles'
import { useTheme } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import TuneIcon from '@mui/icons-material/Tune'
import CloseIcon from '@mui/icons-material/Close'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import Swal from 'sweetalert2'
import saveAnimation from '../../assets/image/cloudSave.json'
import { useDispatch, useSelector } from 'react-redux'

import {
  setChatSummary,
  setIsAudioSupport,
  setIsImageSupport,
  setIsPublished,
  setJsonModeCompare,
  setMessagesCompare,
  setMessagesCompareInitial,
  setMessagesInitial,
  setParameters,
  setResponseFormatCompare,
  setSelectedAi,
  setSelectedModel,
  setSelectedModelCompare,
  setSystemValue,
  setSystemValueCompare,
  setVariableCompareKey,
  setVariableKey,
  setVariables,
  setVariablesCompare,
} from '../../redux/slice/Conversation'
import { useTranslation } from 'react-i18next'
import { Variables } from '../variables'
import Lottie from 'lottie-react'
import EndpointDrawer from '../EndpointDrawer'
import CommonStyles from '../../assets/styles/CommonStyles'
import {
  setPublishedPromptVersions,
  setSelectedPromptVersion,
  updatePublishedPromptVersions,
} from '../../redux/slice/UserProjects'
import { useAuth } from '../../AuthContext'
import { createPrompt, getProjectDetails } from '../../redux/action/UserProjectsAction'
import { toast } from 'react-toastify'
import VersionDropdown from '../versionDropdown'
import {
  getPromptVersions,
  getVersionDetails,
  updateIsPublished,
} from '../../redux/action/PlaygroundAction'
import { savePrompt } from '../../redux/action/UserProjectsAction'
import PublishPromptDialog from '../dialog/publishPromptDialog'
import { checkImageSupport, generateNewVersion } from '../../util/helpers'
import UsageDialog from '../dialog/usageDialog'

export const StudioToolbar = ({
  component,
  selectedModelOptionsCompare,
  selectedModelOptions,
  selectedCategoryCompare,
  selectedCategory,
  setSelectedCategoryCompare,
  setSelectedCategory,
  handleRightSidebar,
  handleRightSidebarCompare,
  show,
  handleCompareClick,
  handleCompareClose,
  HandleCopyRightClick,
  HandleCopyLeftClick,
  handleToggle1,
  handleToggle2,
  toggleFirst,
  toggleSecond,
  publishLoading,
  setSelectedModelOptions,
  handleSaveOnCheckout,
  handleToggle,
  variableOpen,
}) => {
  const Theme = useTheme()
  const isMenu = USEISMENU()
  const isMobile = USEISMOBILE()

  const [saveAnimationVisibility, setSaveAnimationVisibility] = useState(false)
  const [version, setVersion] = useState(VERSIONS[0])
  const [endpointOpen, setEndpointOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState('createVersion')
  const [save, setSave] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [lastSaved, setLastSaved] = useState('12:00:00')
  const [publishDialog, setPublishDialog] = useState(false)
  const [usageDialogOpen, setUsageDialogOpen] = useState(false)

  const [lclComitFlag, setLclComitFlag] = useState(false)

  const { userInfo } = useAuth()
  const dispatch = useDispatch()

  const {
    selectedModelCompare,
    selectedModel,
    messages,
    messagesCompare,
    isPublished,
    systemValue,
    systemValueCompare,
    chatSummary,
    variables,
    variablesCompare,
    variableKey,
    parameters,
    jsonMode,
    jsonModeCompare,
    responseFormat,
    responseFormatCompare,
  } = useSelector((state) => state.conversations)

  const { promptVersions, selectedPromptVersion, publishedPromptVersions } = useSelector(
    (state) => state.UserProjects,
  )
  const { selectedProjectId, selectedFolderId, selectedPrompt } = useSelector(
    (state) => state.UserProjects,
  )
  const isCompareToggle = USEISCOMPARETOGGLE()
  const { t, ready } = useTranslation()
  const ismobile = USEISMOBILE()

  const handleSaveClick = () => {
    const NewMessages = []
    messages?.map((item) => {
      NewMessages.push({
        role: item.role,
        content: item.content.map((contentItem) => {
          if (contentItem.type === 'text') {
            return {
              type: 'text',
              text:
                typeof contentItem.text === 'string'
                  ? contentItem.text
                  : JSON.stringify(contentItem.text),
            }
          } else if (contentItem.type === 'file') {
            return {
              type: 'file',
              file_url: contentItem.file_url,
            }
          }
          // Handle any other content types if necessary
          return contentItem
        }),
      })
    })

    const modifiedVariables = []

    variables.map((item) => {
      modifiedVariables.push({ name: item.key, value: item.value })
    })

    const savePromptData = {
      messages: NewMessages,
      isPublished: isPublished,

      platform: selectedModel === 'claudeai' ? 'claude' : selectedModel,
      model: selectedCategory,
      variable: modifiedVariables,
      system_message: systemValue,
      temp: parameters[selectedModel]?.temperature || 1,
      max_tokens: parameters[selectedModel]?.maxTokens || 1,
      top_p: parameters[selectedModel]?.topP || null,
      frequency_penalty: parameters[selectedModel]?.frequencyPenalty || 1,
      presence_penalty: parameters[selectedModel]?.presencePenalty || 1,
      top_k: parameters[selectedModel]?.topK || 1,
      ...(selectedModel === 'openai' &&
        jsonMode &&
        MODEL_FEATURES[selectedCategory]?.jsonMode &&{
          response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormat) },
        }),
      ...(selectedModel === 'gemini' &&
        jsonMode &&
        MODEL_FEATURES[selectedCategory]?.jsonMode && {
          response_format: JSON.parse(responseFormat),
        }),
    }

    setIsSaving(true)
    try {
      dispatch(
        savePrompt({
          userId: userInfo.userId,
          projectId: selectedProjectId,
          folderId: selectedFolderId,
          promptId: selectedPrompt?.promptId,
          version: promptVersions[selectedPromptVersion],
          savePromptData,
          meta: {
            onSuccess: (res) => {
              setIsSaving(false)
              toast.success(res?.message)
            },
            onError: (error) => {
              setIsSaving(false)
            },
          },
        }),
      )
    } catch (error) {
      console.log('error for save', error)
    }
  }

  const handlePublishPrompt = async (config) => {
    if (component === 1 && jsonMode && MODEL_FEATURES[selectedCategory]?.jsonMode && !responseFormat) {
      toast.error('Please set the JSON schema first')
      return
    }
    if (component === 2 && jsonModeCompare && MODEL_FEATURES[selectedCategoryCompare]?.jsonMode && !responseFormatCompare) {
      toast.error('Please set the JSON schema first')
      return
    }

    const NewMessages = []
    messages?.map((item) => {
      NewMessages.push({
        role: item.role,
        content: item.content.map((contentItem) => {
          if (contentItem.type === 'text') {
            return {
              type: 'text',
              text:
                typeof contentItem.text === 'string'
                  ? contentItem.text
                  : JSON.stringify(contentItem.text),
            }
          } else if (contentItem.type === 'file') {
            return {
              type: 'file',
              file_url: contentItem.file_url,
            }
          }
          // Handle any other content types if necessary
          return contentItem
        }),
      })
    })

    const modifiedVariables = []

    config.map((item) => {
      modifiedVariables.push({ name: item.key, value: item.value })
    })

    const savePromptData = {
      messages: NewMessages,
      isPublished: isPublished,
      isPromptPublished: true,
      isImageSupport: MODEL_FEATURES[selectedCategory]?.image,
      isAudioSupport: MODEL_FEATURES[selectedCategory]?.audio,
      platform: selectedModel === 'claudeai' ? 'claude' : selectedModel,
      model: selectedCategory,
      variable: modifiedVariables,
      system_message: systemValue,
      temp: parameters[selectedModel]?.temperature || 1,
      max_tokens: parameters[selectedModel]?.maxTokens || 1,
      top_p: parameters[selectedModel]?.topP || null,
      frequency_penalty: parameters[selectedModel]?.frequencyPenalty || 1,
      presence_penalty: parameters[selectedModel]?.presencePenalty || 1,
      top_k: parameters[selectedModel]?.topK || 1,
      ...(selectedModel === 'openai' &&
        jsonMode &&
        MODEL_FEATURES[selectedCategory]?.jsonMode && {
          response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormat) },
        }),
      ...(selectedModel === 'gemini' &&
        jsonMode &&
        MODEL_FEATURES[selectedCategory]?.jsonMode && {
          response_format: JSON.parse(responseFormat),
        }),
    }

    try {
      dispatch(
        // Also used to Publish and save
        savePrompt({
          userId: userInfo?.userId,
          projectId: selectedProjectId,
          folderId: selectedFolderId,
          promptId: selectedPrompt?.promptId,
          version: promptVersions[selectedPromptVersion],
          isPromptPublished: true,
          savePromptData,
          meta: {
            onSuccess: (data) => {
              // const temp = data?.data?.project
              // dispatch(setProjectsData(temp))

              dispatch(setIsPublished(true))
              dispatch(setIsImageSupport(MODEL_FEATURES[selectedCategory]?.image))
              dispatch(setIsAudioSupport(MODEL_FEATURES[selectedCategory]?.audio))
              dispatch(
                updatePublishedPromptVersions({
                  versionId: selectedPromptVersion,
                  version: promptVersions[selectedPromptVersion],
                }),
              )
              toast.success(data?.message)
              setPublishDialog(false)
              dispatch(setVariables(config))
            },
            onError: (error) => {
              console.error('Fetching failed:', error)
              toast.error(error?.data?.message)
            },
          },
        }),
      )
    } catch (error) {
      console.error('An error occurred during login:', error)
    }
  }

  const handlePublishConfirm = (Flag) => {
    if (systemValue === '' && messages.length === 0) {
      toast.warning('Please add system message Or messages')
      return
    }
    setPublishDialog(true)
  }

  const handleEndpointOpen = () => {
    setEndpointOpen(!endpointOpen)
  }

  const updatedPromptVersions =
    promptVersions &&
    Object.fromEntries(
      Object.entries(promptVersions).map(([key, value]) => [
        key,
        Number.isInteger(value) ? value.toFixed(1) : value,
      ]),
    )

  const handleCheckout = async (value, newVersionId) => {
    let savePromptData = {}
    if (value === 'clone') {
      dispatch(setSystemValue(systemValue))
      dispatch(setMessagesInitial(messages))
      dispatch(setChatSummary(chatSummary))

      setSelectedModelOptions(MODEL_OPTIONS[selectedModel])
      setSelectedCategory(MODEL_OPTIONS[selectedModel][0])
      dispatch(setSelectedAi(selectedModel))

      const NewMessages = []
      messages?.map((item) =>
        NewMessages.push({
          role: item.role,
          content: item.content.map((contentItem) => {
            if (contentItem.type === 'text') {
              return {
                type: 'text',
                text:
                  typeof contentItem.text === 'string'
                    ? contentItem.text
                    : JSON.stringify(contentItem.text),
              }
            } else if (contentItem.type === 'file') {
              return {
                type: 'file',
                file_url: contentItem.file_url,
              }
            }
            // Handle any other content types if necessary
            return contentItem
          }),
        }),
      )

      const modifiedVariables = []

      variables.map((item) => modifiedVariables.push({ name: item.key, value: item.value }))

      savePromptData = {
        messages: NewMessages,
        isPublished: false,
        platform: selectedModel === 'claudeai' ? 'claude' : selectedModel,
        model: selectedCategory,
        variable: modifiedVariables,
        system_message: systemValue,
        temp: parameters[selectedModel]?.temperature || 1,
        max_tokens: parameters[selectedModel]?.maxTokens || 1,
        top_p: parameters[selectedModel]?.topP || null,
        frequency_penalty: parameters[selectedModel]?.frequencyPenalty || 1,
        presence_penalty: parameters[selectedModel]?.presencePenalty || 1,
        top_k: parameters[selectedModel]?.topK || 1,
        ...(selectedModel === 'openai' &&
          jsonMode &&
          MODEL_FEATURES[selectedCategory]?.jsonMode && {
            response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormat) },
          }),
        ...(selectedModel === 'gemini' &&
          jsonMode &&
          MODEL_FEATURES[selectedCategory]?.jsonMode && {
            response_format: JSON.parse(responseFormat),
          }),
      }
    }
    if (value === 'empty') {
      dispatch(setSystemValue(''))
      dispatch(setMessagesInitial([]))
      dispatch(setChatSummary(''))
      dispatch(setVariableKey([]))
      dispatch(setVariables([]))

      dispatch(setSelectedModel(AI_MODELS[0]))
      savePromptData = {
        messages: [],
        isPublished: false,
        platform: selectedModel === 'claudeai' ? 'claude' : selectedModel,
        model: selectedCategory,
        variable: [],
        system_message: '',
        temp: parameters[selectedModel]?.temperature || 1,
        max_tokens: parameters[selectedModel]?.maxTokens || 1,
        top_p: parameters[selectedModel]?.topP || null,
        frequency_penalty: parameters[selectedModel]?.frequencyPenalty || 1,
        presence_penalty: parameters[selectedModel]?.presencePenalty || 1,
        top_k: parameters[selectedModel]?.topK || 1,
        //   ...(selectedModel === 'openai' &&
        //     jsonMode &&
        //     !selectedCategory.includes('gpt-3.5-turbo') && {
        //       response_format: { type: 'json_schema', json_schema: JSON.parse(responseFormat) },
        //     }),
        // ...(selectedModel === 'gemini' &&
        //   jsonMode && {
        //     response_format: JSON.parse(responseFormat),
        //   }),
      }
    }
    dispatch(setIsPublished(false))

    handleSaveOnCheckout(savePromptData, newVersionId)
  }

  const checkoutConfirm = () => {
    const newVersion = generateNewVersion(promptVersions)
    Swal.fire({
      title: 'Checkout',
      text: `This action will create a new version ${newVersion}. Would you like to clone the current Version or start with a new, empty version?`,
      icon: 'question',
      input: 'radio',
      inputOptions: {
        clone: 'Clone',
        empty: 'Empty',
      },
      inputValue: 'clone',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose an option!'
        }
      },
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle the selected option here
        handleCheckout(result.value, newVersion)
      }
    })
  }

  const handleClearConfirmation1 = () => {
    if (!messages.length && !variables.length && !systemValue) {
      return
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will clear the chat and system message.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, clear it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        handleClear1()
      }
    })
  }

  const handleClearConfirmation2 = () => {
    if (!messagesCompare.length && !variablesCompare.length && !systemValueCompare) {
      return
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will clear the chat and reset the model.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Theme.palette.primary.main,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, clear it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        handleClear2()
      }
    })
  }

  const handleClear1 = async () => {
    dispatch(setSystemValue(''))
    dispatch(setMessagesInitial([]))
    dispatch(setVariableKey([]))
    dispatch(setVariables([]))
    // dispatch(setSelectedModel(AI_MODELS[0]))

    const savePromptData = {
      messages: [],
      isPublished: false,
      platform: selectedModel === 'claudeai' ? 'claude' : selectedModel,
      model: MODEL_OPTIONS[AI_MODELS[0]][0],
      variable: [],
      system_message: '',
      temp: parameters[selectedModel]?.temperature || 1,
      max_tokens: parameters[selectedModel]?.maxTokens || 1,
      top_p: parameters[selectedModel]?.topP || null,
      frequency_penalty: parameters[selectedModel]?.frequencyPenalty || 1,
      presence_penalty: parameters[selectedModel]?.presencePenalty || 1,
      top_k: parameters[selectedModel]?.topK || 1,
    }

    handleSaveOnCheckout(savePromptData, promptVersions[selectedPromptVersion])
    // await handleSaveClick()
  }
  const handleClear2 = () => {
    dispatch(setSystemValueCompare(''))
    dispatch(setMessagesCompareInitial([]))
    dispatch(setVariableCompareKey([]))
    dispatch(setVariablesCompare([]))

    dispatch(setSelectedModelCompare(AI_MODELS[0]))
    dispatch(setJsonModeCompare(false))
    dispatch(setResponseFormatCompare(''))
  }

  const handleDeleteVersion = () => {
    if (promptVersions[selectedPromptVersion] === 1) {
      toast.error('Cannot delete the first version')
      return
    }
    alert(selectedPromptVersion)
  }

  const handleUsageOpen = () => {
    setUsageDialogOpen(true)
  }

  const handleUsageClose = () => {
    setUsageDialogOpen(false)
  }

  return (
    <MainBox>
      {/* <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between"> */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={5} lg={8}>
          <CompareBox>
            <OptionBox>
              <AIModelDropdown
                menuList={AI_MODELS}
                selectedModel={component === 2 ? selectedModelCompare : selectedModel}
                setSelected={component === 2 ? setSelectedModelCompare : setSelectedModel}
                icon={<UnfoldMoreIcon />}
                id="model-dropdown"
                width={isMenu ? '130px' : isMobile ? '100px' : '190px'}
                disabled={component === 1 && isPublished}
                component={component}
                isPublished={component === 1 && isPublished}
              />
              <AIModelDropdown
                menuList={component === 2 ? selectedModelOptionsCompare : selectedModelOptions}
                selectedModel={component === 2 ? selectedCategoryCompare : selectedCategory}
                setSelected={component === 2 ? setSelectedCategoryCompare : setSelectedCategory}
                icon={<UnfoldMoreIcon />}
                id="category-dropdown"
                width={isMenu ? '130px' : isMobile ? '100px' : '190px'}
                disabled={component === 1 && isPublished}
                category={true}
                component={component}
                isPublished={component === 1 && isPublished}
              />
              {component === 1 && (
                <VersionDropdown
                  // menuList={promptVersions}
                  menuList={updatedPromptVersions}
                  selectedModel={selectedPromptVersion}
                  setSelected={setSelectedPromptVersion}
                  isDispatch={true}
                  icon={<UnfoldMoreIcon />}
                  id="category-dropdown"
                  width={'110px'}
                  publishedPromptVersions={publishedPromptVersions}
                />
              )}
              {component === 1 && isPublished && (
                <>
                  <PublishButton variant="contained" onClick={() => checkoutConfirm()}>
                    Checkout
                  </PublishButton>

                </>
              )}
              <PublishButton variant="contained" onClick={handleUsageOpen}>
                Usage
              </PublishButton>
            </OptionBox>
          </CompareBox>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={4}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          gap={1}
        >
          {(messages?.length > 0 || variables.length > 0 || systemValue) &&
            component === 1 &&
            !isPublished && (
              <Tooltip title="Clear Chat">
                <IconButton onClick={handleClearConfirmation1}>
                  <CleaningServicesIcon fontSize="large" color="primary" />
                </IconButton>
              </Tooltip>
            )}

          {(messagesCompare.length > 0 || variablesCompare.length > 0 || systemValueCompare) &&
            component === 2 && (
              <Tooltip title="Clear Chat">
                <IconButton onClick={handleClearConfirmation2}>
                  <CleaningServicesIcon fontSize="large" color="primary" />
                </IconButton>
              </Tooltip>
            )}

          {/* {component === 1 && !isPublished && (
            <Tooltip title="Delete Version">
              <IconButton onClick={handleDeleteVersion}>
                <DeleteIcon fontSize="large" color="primary" />
              </IconButton>
            </Tooltip>
          )} */}

          {component === 1 && !isPublished && (
            <Tooltip title="Save">
              <Stack
                direction="column"
                sx={{ width: '50px', height: '50px', position: 'relative' }}
              >
                <Box>
                  <IconButton variant="text" onClick={handleSaveClick} disabled={isSaving}>
                    {isSaving ? (
                      <Box sx={{ width: '36px', height: '36px', pb: '2px' }}>
                        <Lottie loop={true} animationData={saveAnimation} />
                      </Box>
                    ) : save ? (
                      <CloudDoneIcon fontSize="large" color="primary" />
                    ) : (
                      <BackupIcon fontSize="large" color="primary" />
                    )}
                  </IconButton>
                </Box>

                {/* <Box position="absolute" top={40} right={0} width="100%">
                {lastSaved && (
                  <Typography variant="body2" align="center" sx={{ userSelect: 'none' }}>
                    {lastSaved}
                  </Typography>
                )}
              </Box> */}
              </Stack>
            </Tooltip>
          )}
          <Variables
            isPublished={isPublished}
            component={component}
            variableOpen={variableOpen}
            handleToggle={handleToggle}
          />
          {isPublished && (
            <Tooltip title="Curl">
              <IconButton onClick={handleEndpointOpen}>
                <CodeIcon fontSize="large" color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {
            <>
              <Tooltip title="Tune Parameters">
                <IconButton
                  onClick={component === 2 ? handleRightSidebarCompare : handleRightSidebar}
                >
                  <TuneIcon fontSize="large" color="primary" />
                </IconButton>
              </Tooltip>
            </>
          }

          {component === 1 && (
            <>
              {promptVersions && Object.keys(promptVersions).length > 1 ? (
                <CommonStyles.PrimaryButton
                  ismobile={ismobile}
                  onClick={() => handlePublishConfirm('Commit')}
                  sx={{ alignSelf: 'center' }}
                  disabled={isPublished}
                >
                  COMMIT
                </CommonStyles.PrimaryButton>
              ) : (
                <>
                  <CommonStyles.PrimaryButton
                    ismobile={ismobile}
                    disabled={isPublished || publishLoading}
                    onClick={() => handlePublishConfirm('Publish')}
                    sx={{ alignSelf: 'center' }}
                  >
                    {ready ? t('studio.studioToolbar.publish') : 'Publish'}
                  </CommonStyles.PrimaryButton>
                </>
              )}
            </>
          )}

          {!show ? (
            <CompareIconBox onClick={handleCompareClick}>
              {/* <Typography>{ready ? t('studio.studioToolbar.compare') : 'Compare'}</Typography> */}
              <Tooltip title={'Compare'}>
                <CompareArrowsIcon fontSize="large" color="primary" />
              </Tooltip>
            </CompareIconBox>
          ) : (
            component === 2 && (
              <CompareIconBox onClick={handleCompareClose}>
                <CloseIcon />
              </CompareIconBox>
            )
          )}
        </Grid>
        <Grid item display={'flex'} justifyContent={'center'} sm={12} md={12} lg={12}>
          {isCompareToggle && show && (
            <CompareToggleStack>
              <CommonStyles.PrimaryButton
                ismobile={ismobile}
                onClick={handleToggle1}
                disabled={toggleFirst}
              >
                <ArrowBackIcon />
              </CommonStyles.PrimaryButton>
              <CommonStyles.PrimaryButton
                ismobile={ismobile}
                onClick={handleToggle2}
                disabled={toggleSecond}
              >
                <ArrowForwardIcon />
              </CommonStyles.PrimaryButton>

              {toggleSecond && (
                <Tooltip title="Copy to Left">
                  <CommonStyles.PrimaryButton
                    ismobile={ismobile}
                    onClick={() => HandleCopyLeftClick()}
                  >
                    <Stack direction={'row'} alignItems={'center'}>
                      <KeyboardDoubleArrowLeftIcon sx={{ marginRight: 1 }} />
                      <Typography>Copy</Typography>
                    </Stack>
                  </CommonStyles.PrimaryButton>
                </Tooltip>
              )}

              {toggleFirst && (
                <Tooltip title="Copy to right">
                  <CommonStyles.PrimaryButton
                    ismobile={ismobile}
                    onClick={() => HandleCopyRightClick()}
                  >
                    <Stack direction={'row'} alignItems={'center'}>
                      <KeyboardDoubleArrowRightIcon sx={{ marginRight: 1 }} />
                      <Typography>Copy</Typography>
                    </Stack>
                  </CommonStyles.PrimaryButton>
                </Tooltip>
              )}
            </CompareToggleStack>
          )}
        </Grid>
      </Grid>
      {/* </Stack> */}
      <EndpointDrawer endpointOpen={endpointOpen} handleEndpointOpen={handleEndpointOpen} />
      <PublishPromptDialog
        publishDialog={publishDialog}
        setPublishDialog={setPublishDialog}
        handlePublishPrompt={handlePublishPrompt}
        variables={variables}
        variableKey={variableKey}
      />
      <UsageDialog
        open={usageDialogOpen}
        onClose={handleUsageClose}
        versionId={selectedPromptVersion}
      />
    </MainBox>
  )
}
