import React from 'react';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CloseIcon from '@mui/icons-material/Close';

const ChatPopover = ({ messages, onClose }) => {
  if (!messages?.length) return null;

  return (
    <Box sx={{
      maxWidth: 400,
      maxHeight: 500,
      bgcolor: '#f5f5f5',
      borderRadius: 2,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* Header */}
      <Box sx={{
        p: 2,
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
          Conversation Logs
        </Typography>
        <IconButton 
          size="small" 
          onClick={onClose}
          sx={{ 
            color: 'inherit',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* Messages */}
      <Box sx={{
        p: 2,
        overflowY: 'auto',
        flexGrow: 1
      }}>
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: message.role === 'assistant' ? 'row' : 'row-reverse',
              mb: 2,
              gap: 1
            }}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: message.role === 'assistant' ? 'primary.main' : 'secondary.main',
                alignSelf: 'flex-end'
              }}
            >
              {message.role === 'assistant' ? <SmartToyIcon /> : <PersonIcon />}
            </Avatar>
            
            <Box sx={{ maxWidth: '75%' }}>
              <Box
                sx={{
                  backgroundColor: message.role === 'assistant' ? 'primary.light' : 'common.white',
                  borderRadius: 2,
                  p: 1.5,
                  boxShadow: 1,
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    bottom: 8,
                    [message.role === 'assistant' ? 'left' : 'right']: -6,
                    width: 0,
                    height: 0,
                    borderStyle: 'solid',
                    borderWidth: message.role === 'assistant' 
                      ? '6px 6px 6px 0'
                      : '6px 0 6px 6px',
                    borderColor: message.role === 'assistant'
                      ? 'transparent #90caf9 transparent transparent'
                      : 'transparent transparent transparent #ffffff',
                  }
                }}
              >
                <Typography
                  variant="body2"
                  color={message.role === 'assistant' ? 'primary.contrastText' : 'text.primary'}
                  sx={{ wordBreak: 'break-word' }}
                >
                  {Array.isArray(message.content) 
                    ? message.content.map(c => c.text).join(' ')
                    : message.content?.text || message.content}
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                sx={{ 
                  mt: 0.5,
                  display: 'block',
                  textAlign: message.role === 'assistant' ? 'left' : 'right',
                  color: 'text.secondary'
                }}
              >
                {message.role === 'assistant' ? 'Assistant' : 'User'}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ChatPopover;
